<template>
  <div
    class="ui-icon-image"
    :style="styles"
  ></div>
</template>

<script>
export default {
  name: "ui-icon-image",

  props: {
    fullValue: {
      type: String,
      required: true
    },

    size: {
      type: [Number, String],
      required: false,
      default: 24
    }
  },

  computed: {
    styles() {
      let size = parseInt(this.size);
      if (!size) {
        size = 24;
      }

      let src = this.fullValue;

      return {
        display: "inline-block",

        width: `${size}px`,
        height: `${size}px`,
        fontSize: Math.round(size * 0.4) + "px",
        backgroundImage: `url('${src}')`,
        backgroundSize: "cover",
        borderRadius: "4px"
      };
    }
  }
};
</script>